import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const CircularProgressBarWithLogo = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 1) {
          clearInterval(timer);
          return 1;
        }
        return prev + 0.01;
      });
    }, 100);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className ="flex justify-center items-center  h-screen w-full">
    <div className="relative w-[80px] h-[80px]">
      <CircularProgressbar
        value={progress * 100}
        styles={buildStyles({
          pathColor: '#3b5998',
          trailColor: '#e0e0e0',
          strokeLinecap: 'butt',
        })}
      />
      <img 
        src="https://uatquakershillmasjid.s3.ap-southeast-2.amazonaws.com/images/QHMLogoCircle.png" 
        alt="QHM" 
        className="circular-logo"
      />
    </div>
    </div>
  );
};

export default CircularProgressBarWithLogo;
